import React from "react";
import { AuthenticationModal } from "components/organisms/authentication";

function GenericAuthenticationModal(props: any) {
	return (
		<AuthenticationModal
			initialVariant="LOGIN"
			renderContent={({ variant }) => {
				if (variant === "CREATE_ACCOUNT") {
					return (
						<div className="mb-6 grid gap-4 text-center">
							<h2 className="text-heading-sm">Create an account</h2>
						</div>
					);
				}

				if (variant === "PROVIDE_ACCOUNT_DETAILS") {
					return (
						<div className="mb-6 grid gap-4 text-center">
							<h2 className="text-heading-sm">Provide account details</h2>
						</div>
					);
				}

				if (variant === "LOGIN") {
					return (
						<div className="mb-6 grid gap-4 text-center">
							<h2 className="text-heading-sm">Welcome back</h2>
						</div>
					);
				}

				return null;
			}}
			{...props}
		/>
	);
}

export default GenericAuthenticationModal;
