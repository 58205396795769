import * as R from "ramda";

import {
	notificationStates,
	notificationsRoutes,
	notificationsContent,
} from "../../../constants/notifications";

const valueFrom = R.flip(R.prop);

export const sortNotificationsByPriority = R.sortBy(R.prop("sortOrder"));

export const filterNotificationsByIncomplete = R.filter(
	R.propEq("status", notificationStates.INCOMPLETE),
);

export const pathForNotification = R.compose(
	R.defaultTo(null),
	valueFrom(notificationsRoutes),
	R.prop("__typename"),
	R.defaultTo({}),
);

export const contentForNotification = R.compose(
	R.defaultTo(null),
	valueFrom(notificationsContent),
	R.prop("__typename"),
	R.defaultTo({}),
);
