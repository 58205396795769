import { usePathname, useRouter } from "next/navigation";

import useNotificationsQuery from "./useNotificationsQuery";
import useNotificationsNavigation from "./useNotificationsNavigation";

const NotificationsRedirectToNext = () => {
	const router = useRouter();
	const pathname = usePathname();
	const [notifications] = useNotificationsQuery();
	const { next } = useNotificationsNavigation({
		notifications,
		pathname,
	});

	if (next) {
		router.push(next);
	}

	return null;
};

export default NotificationsRedirectToNext;
