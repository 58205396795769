import React from "react";
import * as RadixPopover from "@radix-ui/react-popover";
import clsx from "clsx";

import { TooltipProps, TooltipVariant } from "./types";

export const TouchTooltip = ({
	content,
	variant,
	sideOffset,
	alignOffset,
	animated,
	side,
	children,
	contentStyles,
}: TooltipProps & { contentStyles: string }) => (
	<RadixPopover.Root>
		<RadixPopover.Trigger>{children}</RadixPopover.Trigger>
		<RadixPopover.Portal>
			<RadixPopover.Content
				sideOffset={sideOffset}
				alignOffset={alignOffset}
				side={side}
				className={clsx(
					contentStyles,
					// Aniamtion
					animated && [
						"radix-state-open:radix-side-top:animate-slide-down-fade",
						"radix-state-open:radix-side-bottom:animate-slide-up-fade",
						"radix-state-open:radix-side-left:animate-slide-right-fade",
						"radix-state-open:radix-side-right:animate-slide-left-fade",
					],
				)}
			>
				{content}
				<RadixPopover.Arrow
					className={clsx(
						variant === TooltipVariant.Default && ["fill-neutral-600"],
						variant === TooltipVariant.Light && ["fill-white"],
					)}
				/>
			</RadixPopover.Content>
		</RadixPopover.Portal>
	</RadixPopover.Root>
);
