import React from "react";

const SvgCross = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.464 23.438l-10.18 10.18-2.97-2.97 10.18-10.18L7.313 10.286l2.97-2.97 10.181 10.182L30.647 7.315l2.97 2.97-10.183 10.183L33.616 30.65l-2.97 2.97-10.182-10.182z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgCross;
