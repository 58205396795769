import React from "react";

const SvgQuestion = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.919 25.205h4.54v-1.268c.122-2.218 2.068-3.843 3.973-5.626C27.257 16.569 29 14.746 29 11.933 29 8.09 26.284 5 20.486 5 15.054 5 11.284 8.249 11 13.438h4.703c.284-2.733 2.027-4.476 4.54-4.476 2.473 0 3.608 1.307 3.608 3.328 0 1.703-1.337 3.01-2.837 4.476-1.946 1.862-4.095 3.804-4.095 6.934v1.505zM19.149 34c1.783 0 3.243-1.347 3.243-3.05 0-1.744-1.46-3.09-3.243-3.09-1.825 0-3.325 1.346-3.325 3.09 0 1.703 1.5 3.05 3.325 3.05z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgQuestion;
