import PropTypes from "prop-types";
import * as R from "ramda";

export const sourcesOfFunds = {
	BUSINESS_INCOME: "BUSINESS_INCOME",
	GIFTS: "GIFTS",
	INHERITANCES: "INHERITANCES",
	LOAN_DRAWDOWN: "LOAN_DRAWDOWN",
	RENTAL_INCOME: "RENTAL_INCOME",
	SALARY_AND_WAGES: "SALARY_AND_WAGES",
	SALE_OF_PROPERTY: "SALE_OF_PROPERTY",
	SALE_OF_SHARES: "SALE_OF_SHARES",
};

export const sourcesOfFundsLabels = {
	[sourcesOfFunds.BUSINESS_INCOME]: "Business income",
	[sourcesOfFunds.GIFTS]: "Gifts",
	[sourcesOfFunds.INHERITANCES]: "Inheritances",
	[sourcesOfFunds.LOAN_DRAWDOWN]: "Loan drawdown",
	[sourcesOfFunds.RENTAL_INCOME]: "Rental income",
	[sourcesOfFunds.SALARY_AND_WAGES]: "Salary and wages",
	[sourcesOfFunds.SALE_OF_PROPERTY]: "Sale of property",
	[sourcesOfFunds.SALE_OF_SHARES]: "Sale of shares",
};

export const sourcesOfFundsOptions = R.compose(
	R.map(([value, label]) => ({ label, value })),
	R.toPairs,
)(sourcesOfFundsLabels);

export const sourcesOfFundsPropType = PropTypes.oneOf(
	Object.values(sourcesOfFunds),
);
