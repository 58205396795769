import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";

import { TooltipProps, TooltipVariant } from "./types";

export const MouseToolTip = ({
	content,
	variant,
	sideOffset,
	alignOffset,
	animated,
	side,
	contentStyles,
	children,
}: TooltipProps & { contentStyles: string }) => (
	<RadixTooltip.Provider>
		<RadixTooltip.Root>
			<RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
			<RadixTooltip.Portal>
				<RadixTooltip.Content
					sideOffset={sideOffset}
					alignOffset={alignOffset}
					side={side}
					className={clsx(
						contentStyles,
						// Aniamtion
						animated && [
							"radix-state-delayed-open:radix-side-top:animate-slide-down-fade",
							"radix-state-delayed-open:radix-side-bottom:animate-slide-up-fade",
							"radix-state-delayed-open:radix-side-left:animate-slide-right-fade",
							"radix-state-delayed-open:radix-side-right:animate-slide-left-fade",
						],
					)}
				>
					{content}
					<RadixTooltip.Arrow
						className={clsx(
							variant === TooltipVariant.Default && ["fill-neutral-600"],
							variant === TooltipVariant.Light && ["fill-white"],
						)}
					/>
				</RadixTooltip.Content>
			</RadixTooltip.Portal>
		</RadixTooltip.Root>
	</RadixTooltip.Provider>
);
