import React from "react";
import clsx from "clsx";

const SvgSuccess = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 26 26"
		fill="none"
		className={clsx("text-success", className)}
		{...props}
	>
		<circle cx={13.083} cy={13.085} r={12.5} fill="currentColor" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.752 14.906l5.85-6.701 1.62 1.414-7.286 8.345-4.99-4.491 1.437-1.598 3.369 3.031z"
			fill="#FFFFFF"
		/>
	</svg>
);

export default SvgSuccess;
