import React, { Fragment } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Breakpoint, breakpoints } from "./breakpoints";

function getMediaQuery({
	from,
	to,
}: {
	from?: Breakpoint;
	to?: Breakpoint;
}): string {
	if (to && !from) {
		return `(min-width: ${breakpoints[to]})`;
	}

	if (from && !to) {
		return `(max-width: calc(${breakpoints[from]} - 1px))`;
	}

	if (from && to) {
		return `(min-width: ${breakpoints[from]}) and (max-width: ${breakpoints[to]})`;
	}

	return "";
}

export const Hidden = ({
	children,
	from,
	to,
}: {
	from?: Breakpoint;
	to?: Breakpoint;
	children: React.ReactNode;
}) => {
	const mediaQuery = getMediaQuery({ from, to });
	const matchesQuery = useMediaQuery(mediaQuery);

	return matchesQuery ? <Fragment>{children}</Fragment> : null;
};
