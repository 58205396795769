import PropTypes from "prop-types";

export const notificationStates = {
	COMPLETE: "COMPLETE",
	INCOMPLETE: "INCOMPLETE",
};

export const notificationStatusPropType = PropTypes.oneOf(
	Object.values(notificationStates),
);
