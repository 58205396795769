import { notificationTypenames } from "./notification-typenames";

export const NOTIFICATIONS_BASE_ROUTE = "/notifications";

export const notificationsSlugs = {
	[notificationTypenames.VerifyAddressNotification]: "verify-address",
	[notificationTypenames.AddInvestingEntityNotification]:
		"add-investing-entity",
	[notificationTypenames.ProvideSourceOfFundsNotification]:
		"provide-source-of-funds",
	[notificationTypenames.VerifyIdentityNotification]: "verify-identity",
	[notificationTypenames.VerifyBiometricIdentityNotification]:
		"verify-identity",
	[notificationTypenames.ConfirmEmailAddressNotification]:
		"confirm-email-address",
	[notificationTypenames.ProvideBeneficialOwnerContactDetailsNotification]:
		"provide-beneficial-owner-contact-details",
	[notificationTypenames.ProvideCityOfBirthNotification]:
		"provide-city-of-birth",
	[notificationTypenames.ProvideInvestingEntityDocumentsNotification]:
		"provide-investing-entity-documents",
	[notificationTypenames.ReattemptVerifyBiometricIdentityNotification]:
		"reattempt-verify-identity",
	[notificationTypenames.ReattemptVerifyAddressNotification]:
		"reattempt-verify-address",
	[notificationTypenames.ReattemptProvideSourceOfFundsNotification]:
		"reattempt-provide-source-of-funds",
	[notificationTypenames.AddBankAccountNotification]: "add-bank-account",
	[notificationTypenames.VerifyNZDBankAccountNotification]:
		"verify-bank-account-nzd",
	[notificationTypenames.VerifyAUDBankAccountNotification]:
		"verify-bank-account-aud",
	[notificationTypenames.VerifyEURBankAccountNotification]:
		"verify-bank-account-eur",
	[notificationTypenames.VerifyGBPBankAccountNotification]:
		"verify-bank-account-gbp",
	[notificationTypenames.VerifyUSDBankAccountNotification]:
		"verify-bank-account-usd",
	[notificationTypenames.RenewAustralianAccreditationNotification]:
		"renew-accreditation-au",
	[notificationTypenames.RenewNewZealandAccreditationNotification]:
		"renew-accreditation-nz",
	[notificationTypenames.RenewAccreditationNotification]: "renew-accreditation",
	[notificationTypenames.ConfirmAustralianAccreditationNotification]:
		"confirm-accreditation-au",
	[notificationTypenames.ConfirmNewZealandAccreditationNotification]:
		"confirm-accreditation-nz",
	[notificationTypenames.ConfirmAccreditationNotification]:
		"confirm-accreditation",
	[notificationTypenames.ReattemptConfirmAccreditationNotification]:
		"reattempt-accreditation",
	[notificationTypenames.ConfirmUnsupportedCountryAccreditationNotification]:
		"confirm-accreditation",
};

export const notificationsRoutes = {
	[notificationTypenames.VerifyAddressNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyAddressNotification}`,
	[notificationTypenames.AddInvestingEntityNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.AddInvestingEntityNotification}`,
	[notificationTypenames.ProvideSourceOfFundsNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ProvideSourceOfFundsNotification}`,
	[notificationTypenames.VerifyIdentityNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyIdentityNotification}`,
	[notificationTypenames.VerifyBiometricIdentityNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyBiometricIdentityNotification}`,
	[notificationTypenames.ConfirmEmailAddressNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ConfirmEmailAddressNotification}`,
	[notificationTypenames.ProvideBeneficialOwnerContactDetailsNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ProvideBeneficialOwnerContactDetailsNotification}`,
	[notificationTypenames.ProvideCityOfBirthNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ProvideCityOfBirthNotification}`,
	[notificationTypenames.ProvideInvestingEntityDocumentsNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ProvideInvestingEntityDocumentsNotification}`,
	[notificationTypenames.ReattemptVerifyBiometricIdentityNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ReattemptVerifyBiometricIdentityNotification}`,
	[notificationTypenames.ReattemptVerifyAddressNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ReattemptVerifyAddressNotification}`,
	[notificationTypenames.ReattemptProvideSourceOfFundsNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ReattemptProvideSourceOfFundsNotification}`,
	[notificationTypenames.AddBankAccountNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.AddBankAccountNotification}`,
	[notificationTypenames.VerifyNZDBankAccountNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyNZDBankAccountNotification}`,
	[notificationTypenames.VerifyAUDBankAccountNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyAUDBankAccountNotification}`,
	[notificationTypenames.VerifyEURBankAccountNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyEURBankAccountNotification}`,
	[notificationTypenames.VerifyGBPBankAccountNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyGBPBankAccountNotification}`,
	[notificationTypenames.VerifyUSDBankAccountNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.VerifyUSDBankAccountNotification}`,
	[notificationTypenames.RenewAustralianAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.RenewAustralianAccreditationNotification}`,
	[notificationTypenames.RenewNewZealandAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.RenewNewZealandAccreditationNotification}`,
	[notificationTypenames.RenewAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.RenewAccreditationNotification}`,
	[notificationTypenames.ConfirmAustralianAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ConfirmAustralianAccreditationNotification}`,
	[notificationTypenames.ConfirmNewZealandAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ConfirmNewZealandAccreditationNotification}`,
	[notificationTypenames.ConfirmAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ConfirmAccreditationNotification}`,
	[notificationTypenames.ReattemptConfirmAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ReattemptConfirmAccreditationNotification}`,
	[notificationTypenames.ConfirmUnsupportedCountryAccreditationNotification]: `${NOTIFICATIONS_BASE_ROUTE}/${notificationsSlugs.ConfirmAccreditationNotification}`,
};
