import React from "react";
import clsx from "clsx";

const SvgInfo = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 25 24"
		fill="none"
		className={clsx("fill-blue-400", className)}
		{...props}
	>
		<path d="M22.43 18.74c1.31-1.92 2.07-4.24 2.07-6.74 0-6.63-5.37-12-12-12S.5 5.37.5 12s5.37 12 12 12c2.5 0 4.82-.77 6.74-2.07l4.19 1.65a.5.5 0 0 0 .65-.65l-1.65-4.19zm-8.85-1.68h-2.17v-5.79h2.17v5.79zm0-7.96h-2.17V6.94h2.17V9.1z" />
	</svg>
);

export default SvgInfo;
