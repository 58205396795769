"use client";

import React from "react";
import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import clsx from "clsx";

export function NavLink({
	className,
	href,
	activeClass = "active",
	partialMatch = false,
	...props
}: LinkProps & {
	activeClass?: string;
	className?: string;
	partialMatch?: boolean;
	children: React.ReactNode;
}) {
	const pathname = usePathname();

	const isActive = partialMatch
		? pathname?.includes(href as string)
		: pathname === href || pathname?.endsWith(href as string);

	return (
		<Link
			href={href}
			className={clsx(className, isActive && activeClass)}
			{...props}
		/>
	);
}
