import React from "react";

// eslint-disable-next-line no-restricted-imports
import { toast as JUXToast, ToastOptions } from "@jasperlabs/jux-next";

const GENERIC_ERROR_MESSAGE =
	"Something went wrong and we have recorded this incident. We're working to fix it, so please try again later.";

/**
 * Functions for displaying toasts
 */
export const toast = {
	/**
	 * Clears any visible toasts, then displays a toast with an info icon and the
	 * given message
	 */
	info: (content: React.ReactNode, options?: ToastOptions) => {
		// See ToastContainer component, where we set limit=1 - this means that only
		// one toast can be displayed at a time. In react-toastify, the
		// implementation adds toasts to a queue and waits to render them until any
		// existing toasts have expired. We want new toasts to always be rendered
		// immediately, so we call toast.dismiss() which dismisses all toasts on the
		// screen.
		JUXToast.dismiss();
		JUXToast.info(content, options);
	},
	/**
	 * Clears any visible toasts, then displays a toast with a warning icon and
	 * the given message
	 */
	warning: (content: React.ReactNode, options?: ToastOptions) => {
		JUXToast.dismiss();
		JUXToast.warning(content, options);
	},
	/**
	 * Clears any visible toasts, then displays a toast with a success icon and
	 * the given message
	 */
	success: (content: React.ReactNode, options?: ToastOptions) => {
		JUXToast.dismiss();
		JUXToast.success(content, options);
	},
	/**
	 * Clears any visible toasts, then displays a toast with an error icon and
	 * the given message
	 */
	error: (content: React.ReactNode, options?: ToastOptions) => {
		JUXToast.dismiss();
		JUXToast.error(content, options);
	},
	/**
	 * Clears any visible toasts, then displays a toast with a warning icon and a
	 * generic error message:
	 *
	 * "Something went wrong and we have recorded this incident. We're working to fix it, so please try again later."
	 */
	errorGeneric: (options?: ToastOptions) => {
		JUXToast.dismiss();
		JUXToast.warning(GENERIC_ERROR_MESSAGE, options);
	},
	dismiss: JUXToast.dismiss,
};
