import type { PopperContentProps } from "@radix-ui/react-tooltip";

export enum TooltipVariant {
	Default = "default",
	Light = "light",
}

export type TooltipProps = {
	children: React.ReactNode;
	content: React.ReactNode;
	/**
	 * The variant/color scheme of the tooltip.
	 * @default TooltipVariant.Default
	 */
	variant?: TooltipVariant;
	/**
	 * Animate the tooltip
	 * @default true
	 */
	animated?: boolean;
	sideOffset?: number;
	alignOffset?: number;
	/**
	 * Default side to align the tooltip to.
	 * @default Radix.PopperContentProps["side"].Top
	 */
	side?: PopperContentProps["side"];
};
