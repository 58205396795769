import React from "react";
import clsx from "clsx";

export enum SpinnerVariant {
	Primary = "primary",
	White = "white",
	Neutral = "neutral",
}

export type SpinnerProps = {
	variant?: SpinnerVariant;
	size?: number;
	className?: string;
};

export const Spinner = ({
	variant = SpinnerVariant.Primary,
	size = 40,
	className,
	...rest
}: SpinnerProps) => (
	<span
		style={{
			width: `${size}px`,
			height: `${size}px`,
			borderWidth: `${size / 10}px`,
		}}
		className={clsx(
			variant === SpinnerVariant.Primary && "border-l-primary border-t-primary",
			variant === SpinnerVariant.White && "border-l-white border-t-white",
			variant === SpinnerVariant.Neutral &&
				"border-t-neutral-600 border-l-neutral-600",
			"inline-flex animate-spin rounded-full border-[4px] border-r-transparent border-b-transparent",
			className,
		)}
		{...rest}
	/>
);
