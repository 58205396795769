import { useMemo } from "react";
import * as R from "ramda";

import {
	notificationStates,
	NOTIFICATIONS_BASE_ROUTE,
} from "../../../constants/notifications";

import {
	sortNotificationsByPriority,
	pathForNotification,
} from "./notificationEnhancers";

const isIncomplete = R.compose(
	R.propEq("status", notificationStates.INCOMPLETE),
	R.defaultTo({}),
);

const previousPathFor = (index, notifications) => {
	const previousIndex = index - 1;
	const previousNotification = notifications[previousIndex];
	const previousPath = pathForNotification(previousNotification);

	// If out of bounds, there is no previous path
	if (previousIndex < 0) {
		return null;
	}

	if (isIncomplete(previousNotification) && previousPath) {
		return previousPath;
	}

	return previousPathFor(previousIndex, notifications);
};

const nextPathFor = (index, notifications) => {
	const nextIndex = index + 1;
	const nextNotification = notifications[nextIndex];
	const nextPath = pathForNotification(nextNotification);

	// If out of bounds, notifications is the next path
	if (nextIndex > notifications.length) {
		return NOTIFICATIONS_BASE_ROUTE;
	}

	if (isIncomplete(nextNotification) && nextPath) {
		return nextPath;
	}

	return nextPathFor(nextIndex, notifications);
};

const useNotificationsNavigation = ({
	pathname,
	notifications,
	state = null,
}) => {
	const sortedNotifications = useMemo(
		() => sortNotificationsByPriority(notifications),
		[notifications],
	);
	const currentNotification = sortedNotifications.find(
		R.compose(R.equals(pathname), pathForNotification),
	);
	const currentIndex = R.indexOf(currentNotification, sortedNotifications);

	const { referrer = null } = state || {};

	return useMemo(
		() => ({
			next: referrer ?? nextPathFor(currentIndex, sortedNotifications),
			previous: previousPathFor(currentIndex, sortedNotifications),
		}),
		[sortedNotifications, referrer, currentIndex],
	);
};

export default useNotificationsNavigation;
