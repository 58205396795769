import { usePathname, useRouter } from "next/navigation";
import { useMutationOnSuccess } from "../../../api";

import useNotificationsQuery from "./useNotificationsQuery";
import useNotificationsNavigation from "./useNotificationsNavigation";
import { toast } from "../toast";

export const useNotificationsOnComplete = ({
	successMessage = "Thank you for submitting your information.",
	showSuccessToast = true,
} = {}) => {
	const router = useRouter();
	const pathname = usePathname();
	const [notifications] = useNotificationsQuery();
	const { next } = useNotificationsNavigation({
		notifications,
		pathname,
	});

	return () => {
		if (showSuccessToast) {
			toast.success(successMessage);
		}
		router.push(next);
	};
};

const useNotificationsMutationOnSuccess = (mutation) => {
	const onComplete = useNotificationsOnComplete();
	return useMutationOnSuccess(onComplete, mutation);
};

export default useNotificationsMutationOnSuccess;
