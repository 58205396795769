export const notificationQueryTypes = {
	ADD_BANK_ACCOUNT: "ADD_BANK_ACCOUNT",
	ADD_INVESTING_ENTITY: "ADD_INVESTING_ENTITY",
	CONFIRM_EMAIL_ADDRESS: "CONFIRM_EMAIL_ADDRESS",
	CONFIRM_IDENTITY_DOCUMENT_DETAILS: "CONFIRM_IDENTITY_DOCUMENT_DETAILS",
	RENEW_AUSTRALIAN_ACCREDITATION: "RENEW_AUSTRALIAN_ACCREDITATION",
	RENEW_NEW_ZEALAND_ACCREDITATION: "RENEW_NEW_ZEALAND_ACCREDITATION",
	RENEW_ACCREDITATION: "RENEW_ACCREDITATION",
	CONFIRM_AUSTRALIAN_ACCREDITATION: "CONFIRM_AUSTRALIAN_ACCREDITATION",
	CONFIRM_NEW_ZEALAND_ACCREDITATION: "CONFIRM_NEW_ZEALAND_ACCREDITATION",
	CONFIRM_ACCREDITATION: "CONFIRM_ACCREDITATION",
	CONFIRM_UNSUPPORTED_COUNTRY_ACCREDITATION:
		"CONFIRM_UNSUPPORTED_COUNTRY_ACCREDITATION",
	PROVIDE_BENEFICIAL_OWNER_CONTACT_DETAILS:
		"PROVIDE_BENEFICIAL_OWNER_CONTACT_DETAILS",
	PROVIDE_CITY_OF_BIRTH: "PROVIDE_CITY_OF_BIRTH",
	PROVIDE_SOURCE_OF_FUNDS: "PROVIDE_SOURCE_OF_FUNDS",
	PROVIDE_INVESTING_ENTITY_DOCUMENTS: "PROVIDE_INVESTING_ENTITY_DOCUMENTS",
	VERIFY_ADDRESS: "VERIFY_ADDRESS",
	VERIFY_IDENTITY: "VERIFY_IDENTITY",
	VERIFY_NZD_BANK_ACCOUNT: "VERIFY_NZD_BANK_ACCOUNT",
	VERIFY_AUD_BANK_ACCOUNT: "VERIFY_AUD_BANK_ACCOUNT",
	VERIFY_USD_BANK_ACCOUNT: "VERIFY_USD_BANK_ACCOUNT",
	VERIFY_GBP_BANK_ACCOUNT: "VERIFY_GBP_BANK_ACCOUNT",
	VERIFY_EUR_BANK_ACCOUNT: "VERIFY_EUR_BANK_ACCOUNT",
	VERIFY_BIOMETRIC_IDENTITY: "VERIFY_BIOMETRIC_IDENTITY",
	REATTEMPT_VERIFY_ADDRESS: "REATTEMPT_VERIFY_ADDRESS",
	REATTEMPT_PROVIDE_SOURCE_OF_FUNDS: "REATTEMPT_PROVIDE_SOURCE_OF_FUNDS",
	REATTEMPT_VERIFY_BIOMETRIC_IDENTITY: "REATTEMPT_VERIFY_BIOMETRIC_IDENTITY",
	REATTEMPT_CONFIRM_ACCREDITATION: "REATTEMPT_CONFIRM_ACCREDITATION",
};
