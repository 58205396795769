import PropTypes from "prop-types";

export const prescribedInvestorRates = {
	RATE_0: "RATE_0",
	RATE_1050: "RATE_1050",
	RATE_1750: "RATE_1750",
	RATE_2800: "RATE_2800",
};

export const prescribedInvestorRatesLabels = {
	RATE_0: "0%",
	RATE_1050: "10.5%",
	RATE_1750: "17.5%",
	RATE_2800: "28%",
};

export const prescribedInvestorRatesOptions = Object.entries(
	prescribedInvestorRatesLabels,
).map(([value, label]) => ({ label, value }));

export const prescribedInvestorRatesPropType = PropTypes.oneOf(
	Object.values(prescribedInvestorRates),
);
