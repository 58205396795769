import { useWindowSize } from "usehooks-ts";

export enum Breakpoint {
	/**
	 * 0 - 22.5em (360px)
	 */
	XS = "xs",
	/**
	 * 22.5em / 360px
	 */
	SM = "sm",
	/**
	 * 37.5em / 600px
	 */
	MD = "md",
	/**
	 * 60em / 960px
	 */
	LG = "lg",
	/**
	 * 87.5em / 1400px
	 */
	XL = "xl",
}

/**
 * Breakpoints as specified in tailwind config
 * TODO: These should come from the theme rather than being hardcoded. We can't
 * import the theme and use tailwind's `resolveConfig` helper for this because
 * the theme is specified as a CJS module and webpack complains in consuming
 * apps when mixing CJS & ESM
 */
export const breakpoints = {
	[Breakpoint.XS]: "0em",
	[Breakpoint.SM]: "22.5em",
	[Breakpoint.MD]: "37.5em",
	[Breakpoint.LG]: "60em",
	[Breakpoint.XL]: "85.375em",
};

// Hope that the config specifies the breakpoints in ems (copied from old JUX)
// Otherwise this will be wrong - TODO: Update to be less hacky
const convertEmsToPixels = (emValue: string) =>
	parseFloat(emValue.replace("em", "")) * 16;

export function useBreakpoint(): Breakpoint {
	const { width } = useWindowSize();
	let breakpoint = Breakpoint.XS;
	if (width > convertEmsToPixels(breakpoints.sm)) {
		breakpoint = Breakpoint.SM;
	}
	if (width > convertEmsToPixels(breakpoints.md)) {
		breakpoint = Breakpoint.MD;
	}
	if (width > convertEmsToPixels(breakpoints.lg)) {
		breakpoint = Breakpoint.LG;
	}
	if (width > convertEmsToPixels(breakpoints.xl)) {
		breakpoint = Breakpoint.XL;
	}
	return breakpoint;
}

function decrementBreakpoint(breakpoint: Breakpoint): Breakpoint {
	switch (breakpoint) {
		case Breakpoint.XL:
			return Breakpoint.LG;
		case Breakpoint.LG:
			return Breakpoint.MD;
		case Breakpoint.MD:
			return Breakpoint.SM;
		case Breakpoint.SM:
			return Breakpoint.XS;
		default:
			return Breakpoint.XS;
	}
}

function valueAtBreakpoint<T>(
	breakpoint: Breakpoint,
	values: Partial<Record<Breakpoint, T>>,
): T {
	if (breakpoint in values) {
		return values[breakpoint]!;
	}

	return valueAtBreakpoint(decrementBreakpoint(breakpoint), values);
}

export function useValueAtBreakpoint<T>(
	values: Partial<Record<Breakpoint, T>>,
): T {
	const breakpoint = useBreakpoint();

	return valueAtBreakpoint(breakpoint, values);
}
