import { useApolloClient } from "@apollo/client";

const useNotificationsSetCompleteInCache = (...notifications) => {
	const { cache } = useApolloClient();

	return () => {
		notifications.forEach((notification) => {
			cache.modify({
				id: cache.identify(notification),
				fields: {
					status: () => "COMPLETE",
				},
			});
		});
	};
};

export default useNotificationsSetCompleteInCache;
