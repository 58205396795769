import React from "react";

import { notificationTypenames } from "./notification-typenames";

const notificationImages = {
	bank: "/assets/images/notifications/icons/bank.svg",
	funds: "/assets/images/notifications/icons/funds.svg",
	entity: "/assets/images/notifications/icons/entity.svg",
	personTick: "/assets/images/notifications/icons/person-tick.svg",
	envelopeTick: "/assets/images/notifications/icons/envelope-tick.svg",
	locationMarker: "/assets/images/notifications/icons/location-marker.svg",
	personDocument: "/assets/images/notifications/icons/person-document.svg",
	identityDocument: "/assets/images/notifications/icons/identity-document.svg",
	locationDocument: "/assets/images/notifications/icons/location-document.svg",
};

export const notificationsContent = {
	[notificationTypenames.VerifyAddressNotification]: {
		title: () => "Verify your address",
		description: () =>
			"Financial regulations require us to verify your physical address.",
		icon: notificationImages.locationDocument,
	},
	[notificationTypenames.ProvideSourceOfFundsNotification]: {
		title: () => "Provide source of funds",
		description: () =>
			"Financial regulations require us to verify the source of funds for your investing entity.",
		icon: notificationImages.funds,
	},
	[notificationTypenames.AddInvestingEntityNotification]: {
		title: () => "Add an investing entity",
		description: () =>
			"We need to know how you plan to invest. As an individual, as a company, as joint individuals, or as a trust?",
		icon: notificationImages.entity,
	},
	[notificationTypenames.ConfirmEmailAddressNotification]: {
		title: () => "Confirm your email address",
		description: () =>
			"Have we got the right email address to reach you on? To confirm that you can get our emails, confirm your email address.",
		icon: notificationImages.envelopeTick,
	},
	[notificationTypenames.VerifyIdentityNotification]: {
		title: () => "Verify your identity",
		description: () =>
			"For regulatory purposes we need to confirm your identity.",
		icon: notificationImages.identityDocument,
	},
	[notificationTypenames.VerifyBiometricIdentityNotification]: {
		title: () => "Verify your identity",
		description: () =>
			"For regulatory purposes we need to confirm your identity.",
		icon: notificationImages.identityDocument,
	},
	[notificationTypenames.ConfirmIdentityDocumentDetailsNotification]: {
		title: () => "Confirm identity document details",
		description: () =>
			"We couldn't verify your identity document details electronically. Please contact your account manager.",
		icon: notificationImages.identityDocument,
	},
	[notificationTypenames.ProvideBeneficialOwnerContactDetailsNotification]: {
		title: () => "Add contact details for all beneficial owners",
		description: () =>
			"Financial regulations require us to verify the identities for all beneficial owners of your investing entity.",
		icon: notificationImages.personDocument,
	},
	[notificationTypenames.ProvideCityOfBirthNotification]: {
		title: () => "Provide city of birth",
		description: () =>
			"To comply with financial regulations, there is additional information we need from you.",
		icon: notificationImages.locationMarker,
	},
	[notificationTypenames.ProvideInvestingEntityDocumentsNotification]: {
		title: () => "Add investing entity documents",
		description: () =>
			"We're sorry but we were unable to verify your investing entity with the document supplied. Please upload requested documentation in an approved format.",
		icon: notificationImages.entity,
	},
	[notificationTypenames.ReattemptVerifyBiometricIdentityNotification]: {
		title: () => "Verify your identity",
		description: () =>
			"We're sorry but we were unable to verify your identity with the document supplied. Please try again.",
		icon: notificationImages.identityDocument,
	},
	[notificationTypenames.ReattemptVerifyAddressNotification]: {
		title: () => "Verify your address",
		description: () =>
			"We're sorry but we were unable to electronically verify your address. Please upload a valid proof of address.",
		icon: notificationImages.locationMarker,
	},
	[notificationTypenames.ReattemptProvideSourceOfFundsNotification]: {
		title: () => "Provide source of funds",
		description: () =>
			"We're sorry but we were unable to verify your source of funds with the document supplied. Please upload requested documentation in an approved format.",
		icon: notificationImages.funds,
	},
	[notificationTypenames.AddBankAccountNotification]: {
		title: () => "Add bank account",
		description: () => (
			<>
				We need the bank details for your investing entity so we can pay you
				your share of fund distributions.
			</>
		),
		icon: notificationImages.bank,
	},
	[notificationTypenames.VerifyNZDBankAccountNotification]: {
		title: () => "Verify NZD bank account",
		description: () => (
			<>
				Please verify your NZD bank account so we can pay you your share of fund
				distributions.
			</>
		),
		icon: notificationImages.bank,
	},
	[notificationTypenames.VerifyAUDBankAccountNotification]: {
		title: () => "Verify AUD bank account",
		description: () => (
			<>
				Please verify your AUD bank account so we can pay you your share of fund
				distributions.
			</>
		),
		icon: notificationImages.bank,
	},
	[notificationTypenames.VerifyEURBankAccountNotification]: {
		title: () => "Verify EUR bank account",
		description: () => (
			<>
				Please verify your EUR bank account so we can pay you your share of fund
				distributions.
			</>
		),
		icon: notificationImages.bank,
	},
	[notificationTypenames.VerifyGBPBankAccountNotification]: {
		title: () => "Verify GBP bank account",
		description: () => (
			<>
				Please verify your GBP bank account so we can pay you your share of fund
				distributions.
			</>
		),
		icon: notificationImages.bank,
	},
	[notificationTypenames.VerifyUSDBankAccountNotification]: {
		title: () => "Verify USD bank account",
		description: () => (
			<>
				Please verify your USD bank account so we can pay you your share of fund
				distributions.
			</>
		),
		icon: notificationImages.bank,
	},
	[notificationTypenames.RenewAustralianAccreditationNotification]: {
		title: () => "Renew wholesale certification",
		description: () =>
			"Your wholesale certification has expired. You will need to renew this certificate before you can invest in new wholesale offers.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.RenewNewZealandAccreditationNotification]: {
		title: () => "Renew wholesale certification",
		description: () =>
			"Your wholesale certification has expired. You will need to renew this certificate before you can invest in new wholesale offers.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.RenewAccreditationNotification]: {
		title: () => "Renew wholesale certification",
		description: () =>
			"Your wholesale certification has expired. You will need to renew this certificate before you can invest in new wholesale offers.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.ReattemptConfirmAccreditationNotification]: {
		title: () => "Reattempt wholesale certification",
		description: () =>
			"Your wholesale certification failed. You will need to resubmit this certificate before you can invest in new wholesale offers.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.ConfirmAustralianAccreditationNotification]: {
		title: () => "Confirm wholesale investor status",
		description: () =>
			"Access opportunities that are exclusively available to wholesale investors.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.ConfirmNewZealandAccreditationNotification]: {
		title: () => "Confirm wholesale investor status",
		description: () =>
			"Access opportunities that are exclusively available to wholesale investors.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.ConfirmAccreditationNotification]: {
		title: () => "Confirm wholesale investor status",
		description: () =>
			"Access opportunities that are exclusively available to wholesale investors.",
		icon: notificationImages.personTick,
	},
	[notificationTypenames.ConfirmUnsupportedCountryAccreditationNotification]: {
		title: () => "Confirm wholesale investor status",
		description: () =>
			"Access opportunities that are exclusively available to wholesale investors.",
		icon: notificationImages.personTick,
	},
};
