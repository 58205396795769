import { useMemo } from "react";
import { gql } from "@apollo/client";
import * as R from "ramda";

import { VERIFIABLE_BANK_ACCOUNT } from "api/queries/fragments";

import { NotificationStatus } from "types";

import { useAllNotificationsQuery } from "./useNotificationsQuery.generated";

import { notificationTypenames } from "../../../constants/notifications";

import { sortNotificationsByPriority } from "./notificationEnhancers";

gql`
	query AllNotifications($status: NotificationStatus) {
		account {
			id
			notifications(status: $status) {
				id
				status
				sortOrder
			}
			activeInvestingEntity {
				id
				notifications(status: $status) {
					id
					status
					sortOrder
					... on VerifyNZDBankAccountNotification {
						bankAccount {
							...verifiableBankAccountFields
						}
					}
					... on VerifyAUDBankAccountNotification {
						bankAccount {
							...verifiableBankAccountFields
						}
					}
					... on VerifyEURBankAccountNotification {
						bankAccount {
							...verifiableBankAccountFields
						}
					}
					... on VerifyGBPBankAccountNotification {
						bankAccount {
							...verifiableBankAccountFields
						}
					}
					... on VerifyUSDBankAccountNotification {
						bankAccount {
							...verifiableBankAccountFields
						}
					}
					... on ConfirmUnsupportedCountryAccreditationNotification {
						country {
							id
							name
						}
					}
				}
			}
		}
	}
	${VERIFIABLE_BANK_ACCOUNT}
`;

type QueryOptions = {
	variables?: {
		status?: NotificationStatus;
	};
};

const useNotificationsQuery = (
	options: QueryOptions = {
		variables: {
			status: NotificationStatus.Incomplete,
		},
	},
) => {
	const queryResult = useAllNotificationsQuery({
		...options,
	});

	const account = queryResult.data?.account;

	const notifications = useMemo(() => {
		const blockList = ["VerifyIdentityNotification"];

		// @ts-ignore
		const mergeFilterAndSort = R.compose(
			sortNotificationsByPriority,
			R.reject(({ __typename: typename }) => blockList.includes(typename)),
			R.filter(({ __typename: typename }) => typename in notificationTypenames),
			R.concat,
		);

		const accountNotifications = account?.notifications ?? [];
		const investingEntityNotifications =
			account?.activeInvestingEntity?.notifications ?? [];

		const filteredAndSortedNotifications = mergeFilterAndSort(
			accountNotifications,
			investingEntityNotifications,
		);

		return filteredAndSortedNotifications;
	}, [account]);

	return [notifications, queryResult];
};

export default useNotificationsQuery;
