import React, { forwardRef } from "react";
import clsx from "clsx";

import { Tooltip } from "../../Tooltip";
import { Breakpoint, Hidden } from "../../utils";
import { QuestionMarkReferenceSize } from "../../Tooltip/QuestionMarkReference";
import { PolymorphicRef } from "../../../types/polymorphic-component";

export type FormFieldProps<C extends React.ElementType = "div"> = {
	as?: C;
	id: string;
	className?: string;
	label?: React.ReactNode;
	hint?: React.ReactNode;
	error?: React.ReactNode;
	tooltip?: React.ReactNode;
	description?: React.ReactNode;
	hasError?: boolean;
	disabled?: boolean;
	name?: string;
	isLoading?: boolean;
};

type FormFieldPropsWithChildren<C extends React.ElementType = "div"> =
	FormFieldProps<C> & {
		ref?: PolymorphicRef<C>;
		children: React.ReactNode;
	};

type FormFieldComponent = <C extends React.ElementType = "div">(
	props: FormFieldPropsWithChildren<C>,
) => React.ReactNode;

export const FormField: FormFieldComponent = forwardRef(
	<C extends React.ElementType = "div">(
		{
			children,
			as,
			id,
			className,
			label,
			hint,
			error,
			tooltip,
			description,
			hasError,
			disabled,
			name,
			isLoading = false,
			...props
		}: FormFieldPropsWithChildren<C>,
		ref: PolymorphicRef<C>,
	) => {
		const Component = as ?? "div";

		return (
			<Component
				ref={ref}
				className={clsx("group flex flex-1 flex-col", className)}
				{...props}
			>
				<div className="mb-1 flex items-center gap-2">
					<label
						htmlFor={id}
						className="text-label-default font-medium leading-snug"
					>
						{label}
					</label>
					<Hidden to={Breakpoint.MD}>
						{tooltip && (
							<Tooltip content={tooltip}>
								<Tooltip.QuestionMarkReference />
							</Tooltip>
						)}
					</Hidden>
				</div>
				<div className="flex w-full flex-col">
					{description && <p className="text-body-sm mb-2">{description}</p>}
					<div className="flex w-full">
						<div
							className={clsx(
								"flex w-full flex-1 basis-full gap-4",
								isLoading && ["cursor-progress [&>*]:!cursor-progress"],
							)}
						>
							{React.Children.map(children, (child) => {
								if (!React.isValidElement(child)) {
									return child;
								}

								// Don't add props to DOM elements (only components)
								if (typeof child.type === "string") {
									return child;
								}

								return React.cloneElement(
									child as React.ReactElement<{
										hasError?: boolean;
										disabled?: boolean;
										id?: string;
										name?: string;
									}>,
									{ hasError, disabled, id, name },
								);
							})}
						</div>
						<Hidden from={Breakpoint.MD}>
							{tooltip && (
								<Tooltip content={tooltip}>
									<div className="px-2">
										<Tooltip.QuestionMarkReference
											size={QuestionMarkReferenceSize.Medium}
										/>
									</div>
								</Tooltip>
							)}
						</Hidden>
					</div>
				</div>
				<p
					className={clsx(
						"text-label-default md:text-label-sm mt-1 text-left !font-semibold leading-tight",
						error && "text-form-field-error",
						!error && hint && "text-form-field-hint",
					)}
				>
					{(hasError && error) || hint || <>&nbsp;</>}
				</p>
			</Component>
		);
	},
);
