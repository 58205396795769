import React from "react";
import clsx from "clsx";

const SvgError = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 26 26"
		fill="none"
		className={clsx("text-danger", className)}
		{...props}
	>
		<circle cx={13.104} cy={13.085} r={10.356} fill="#fff" />
		<path
			d="M13.104.585c-6.907 0-12.5 5.593-12.5 12.5 0 6.906 5.593 12.5 12.5 12.5 6.906 0 12.5-5.594 12.5-12.5 0-6.907-5.594-12.5-12.5-12.5zm4.906 15.927L16.54 17.98l-3.427-3.427-3.427 3.427-1.469-1.468 3.417-3.428-3.427-3.427L9.676 8.19l3.428 3.427 3.427-3.427 1.468 1.468-3.416 3.428 3.427 3.427z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgError;
