/**
 * Represents all the notifications known to the web-app
 * For example: A typename defined here implies that a
 * notification card renders in the notifications list.
 */
export const notificationTypenames = {
	AddInvestingEntityNotification: "AddInvestingEntityNotification",
	ConfirmEmailAddressNotification: "ConfirmEmailAddressNotification",
	ProvideBeneficialOwnerContactDetailsNotification:
		"ProvideBeneficialOwnerContactDetailsNotification",
	ProvideCityOfBirthNotification: "ProvideCityOfBirthNotification",
	ProvideSourceOfFundsNotification: "ProvideSourceOfFundsNotification",
	VerifyAddressNotification: "VerifyAddressNotification",
	VerifyIdentityNotification: "VerifyIdentityNotification",
	VerifyBiometricIdentityNotification: "VerifyBiometricIdentityNotification",
	ConfirmIdentityDocumentDetailsNotification:
		"ConfirmIdentityDocumentDetailsNotification",
	ProvideInvestingEntityDocumentsNotification:
		"ProvideInvestingEntityDocumentsNotification",
	ReattemptVerifyBiometricIdentityNotification:
		"ReattemptVerifyBiometricIdentityNotification",
	ReattemptVerifyAddressNotification: "ReattemptVerifyAddressNotification",
	ReattemptProvideSourceOfFundsNotification:
		"ReattemptProvideSourceOfFundsNotification",
	AddBankAccountNotification: "AddBankAccountNotification",
	VerifyNZDBankAccountNotification: "VerifyNZDBankAccountNotification",
	VerifyAUDBankAccountNotification: "VerifyAUDBankAccountNotification",
	VerifyEURBankAccountNotification: "VerifyEURBankAccountNotification",
	VerifyGBPBankAccountNotification: "VerifyGBPBankAccountNotification",
	VerifyUSDBankAccountNotification: "VerifyUSDBankAccountNotification",
	RenewAustralianAccreditationNotification:
		"RenewAustralianAccreditationNotification",
	RenewNewZealandAccreditationNotification:
		"RenewNewZealandAccreditationNotification",
	ConfirmAustralianAccreditationNotification:
		"ConfirmAustralianAccreditationNotification",
	RenewAccreditationNotification: "RenewAccreditationNotification",
	ConfirmNewZealandAccreditationNotification:
		"ConfirmNewZealandAccreditationNotification",
	ConfirmUnsupportedCountryAccreditationNotification:
		"ConfirmUnsupportedCountryAccreditationNotification",
	ConfirmAccreditationNotification: "ConfirmAccreditationNotification",
	ReattemptConfirmAccreditationNotification:
		"ReattemptConfirmAccreditationNotification",
};
