import React from "react";
import clsx from "clsx";

import { IconQuestion } from "../icon";

/**
 * Specifies a icons's color scheme
 */
export enum QuestionMarkReferenceVariant {
	Default = "default",
	Light = "light",
	Transparent = "transparent",
}

/**
 * Specifies a icons's size
 */
export enum QuestionMarkReferenceSize {
	ExtraSmall = "xs",
	Small = "sm",
	Medium = "md",
	Large = "lg",
}

export type QuestionMarkReferenceProps = {
	variant?: QuestionMarkReferenceVariant;
	size?: QuestionMarkReferenceSize;
	className?: string;
};

export const QuestionMarkReference = ({
	variant = QuestionMarkReferenceVariant.Default,
	size = QuestionMarkReferenceSize.Small,
	className,
}: QuestionMarkReferenceProps) => (
	<span
		className={clsx(
			"flex items-center justify-center rounded-full",
			variant === QuestionMarkReferenceVariant.Light && ["text-primary"],
			variant === QuestionMarkReferenceVariant.Default && [
				"bg-neutral-500 text-white",
			],
			variant === QuestionMarkReferenceVariant.Transparent && [
				"bg-neutral-700 bg-opacity-20 text-white",
			],
			size === QuestionMarkReferenceSize.ExtraSmall && [
				"h-[12px] w-[12px] text-[10px]",
			],
			size === QuestionMarkReferenceSize.Small && [
				"h-[15px] w-[15px] text-[11px]",
			],
			size === QuestionMarkReferenceSize.Medium && [
				"h-[25px] w-[25px] text-[15px]",
			],
			size === QuestionMarkReferenceSize.Large && [
				"h-[35px] w-[35px] text-[25px]",
			],
			className,
		)}
	>
		<IconQuestion className="fill-current" />
	</span>
);
