"use client";

import React from "react";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

import { QuestionMarkReference } from "./QuestionMarkReference";

import { MouseToolTip } from "./MouseTooltip";
import { TouchTooltip } from "./TouchTooltip";

import { TooltipProps, TooltipVariant } from "./types";

export const Tooltip = ({
	content,
	variant = TooltipVariant.Default,
	sideOffset = 5,
	alignOffset = 5,
	animated = true,
	side = "top",
	children,
}: TooltipProps) => {
	const isTouch = useMediaQuery("(hover: none)");
	const sharedContentStyles = clsx(
		"z-tooltip text-body-sm rounded-md py-2 px-3 shadow-lg max-w-[310px]",
		variant === TooltipVariant.Default && ["bg-neutral-600 text-white"],
		variant === TooltipVariant.Light && ["text-neutral bg-white"],
	);
	// If we are on a touch device, use the touch tooltip
	if (isTouch) {
		return (
			<TouchTooltip
				content={content}
				variant={variant}
				sideOffset={sideOffset}
				alignOffset={alignOffset}
				animated={animated}
				side={side}
				contentStyles={sharedContentStyles}
			>
				{children}
			</TouchTooltip>
		);
	}

	return (
		<MouseToolTip
			content={content}
			variant={variant}
			sideOffset={sideOffset}
			alignOffset={alignOffset}
			animated={animated}
			side={side}
			contentStyles={sharedContentStyles}
		>
			{children}
		</MouseToolTip>
	);
};

Tooltip.Provider = TooltipProvider;

Tooltip.QuestionMarkReference = QuestionMarkReference;
