import React from "react";
import {
	IconProps,
	toast as toastify,
	ToastContainer as ToastifyContainer,
	ToastContainerProps,
	ToastOptions,
	UpdateOptions,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
	IconCross,
	IconStatusError,
	IconStatusInfo,
	IconStatusSuccess,
	IconStatusWarningTriangle,
} from "../../icon";

export { type ToastOptions, type UpdateOptions, type ToastContainerProps };

const ToastCloseButton = () => (
	<span className="text-xl text-neutral-500 hover:text-neutral-600">
		<IconCross />
	</span>
);

const ToastIcon = ({ type }: IconProps) => (
	<span className="text-2xl">
		{
			{
				info: <IconStatusInfo />,
				success: <IconStatusSuccess />,
				warning: <IconStatusWarningTriangle />,
				error: <IconStatusError />,
				default: null,
			}[type]
		}
	</span>
);

export const ToastContainer = (props: ToastContainerProps) => (
	<ToastifyContainer
		toastClassName="bg-white flex gap-3 w-fit mx-auto items-center pointer-events-auto text-neutral-900 py-3 px-5 rounded-none md:rounded-md shadow-lg text-body-default"
		bodyClassName="flex gap-3 items-center"
		closeButton={<ToastCloseButton />}
		icon={(iconProps) => <ToastIcon {...iconProps} />}
		hideProgressBar
		{...props}
	/>
);

export const toast = {
	success: toastify.success,
	info: toastify.info,
	warning: toastify.warning,
	error: toastify.error,
	dismiss: toastify.dismiss,
	update: toastify.update,
};
